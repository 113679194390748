import { CampaignEntity, Response } from 'models'
import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const update = (id: string, data: ApiRequest.campaign.update) => instanceAxios.patch(`/campaigns/${id}`, data)

const distribute = (id: string, data: ApiRequest.campaign.distribute) => instanceAxios.post(`/campaigns/${id}/distribute`, data)

const show = async (id: string): Promise<any> => instanceAxios.get(`/campaigns/${id}`)

export default {
  update,
  show,
  distribute
}

import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const create = (data: ApiRequest.template.create) => instanceAxios.post(`/campaigns/${data.campaign}/templates`, data)

const update = (id: string, data: ApiRequest.template.update) => instanceAxios.patch(`/campaigns/${data.campaign}/templates/${id}`, data)

const del = async (id: string, campaign_id: string): Promise<any> => instanceAxios.delete(`/campaigns/${campaign_id}/templates/${id}`)
export default {
  create,
  update,
  del
}

import { CampaignEntity } from 'models/campaign'
import { DistributeUnit, DistributeUnitType } from './distribute'
import { OrgUnitEntity } from 'models/org_unit'

export interface CampaignStateEntity {
  campaign?: CampaignEntity
  tab: EnumCampaignTab
  customer_care_id?: string
  me_unit?: OrgUnitEntity
  parent_unit?: OrgUnitEntity
}

export enum EnumCampaignTab {
  CUSTOMER_DATA,
  CUSTOMER_IMPORT,
  CUSTOMER_DISTRIBUTE,
  EMPLOYEE,
  TEMPLATE,
  INFO
}

import { CampaignEntity } from './campaign'
import { EnumCustomerGender } from './customer'
import { EntityBase } from './entity'
import { OrgUnitEntity } from './org_unit'
import { UserEntity } from './user_portal'

export interface SyntaxEntity {
  name: string
  key: TemplateParam
  note: string
}
export type TemplateParam = keyof TemplateParams

export interface TemplateEntity extends EntityBase {
  content: string
  campaign: CampaignEntity
  unit?: OrgUnitEntity
  created_by: UserEntity
  common: boolean
}
export interface TemplateParams {
  customer_gender?: EnumCustomerGender
  customer_full_name: string
  customer_name: string
  customer_phone: string
  carer_name?: string
  customer_birthday?: string
  score_amount?: string
  score_amount_value?: string
  score_expire?: string
  score_expire_countdown?: string
  store_name: string
  store_map_url: string
  store_address: string
}

export const SyntaxConfigs: SyntaxEntity[] = [
  {
    name: 'Tên',
    key: 'customer_name',
    note: "Tên của khách hàng, ví dụ: 'Ms Sen' -> 'Sen', 'Nguyễn Văn Minh' -> 'Minh'"
  },
  {
    name: 'Tên đầy đủ',
    key: 'customer_full_name',
    note: "Tên của khách hàng được nhập vào hệ thống, ví dụ: 'Ms Sen', 'Nguyễn Văn Minh'"
  },
  {
    name: 'Danh xưng',
    key: 'customer_gender',
    note: "Khách nam -> danh xưng là 'anh', khách nữ -> danh xưng là 'chị'"
  },
  {
    name: 'SĐT',
    key: 'customer_phone',
    note: 'Số điện thoại của khách'
  },
  {
    name: 'Nhân viên',
    key: 'carer_name',
    note: 'Tên của nhân viên được chỉ định chăm sóc'
  },
  {
    name: 'Sinh nhật',
    key: 'customer_birthday',
    note: "Ngày sinh của khách hàng, ví dụ: '10/09/2001' "
  },
  {
    name: 'Lượng điểm',
    key: 'score_amount',
    note: 'Lượng tiêu điểm của khách hàng, ví dụ khách có 10 điểm. '
  },
  {
    name: 'Giá trị điểm',
    key: 'score_amount_value',
    note: 'Giá trị tiêu điểm quy đổi ra tiền, ví dụ khách có 10 điểm -> có 10.000 đồng điểm khuyến mãi'
  },
  {
    name: 'HSD điểm',
    key: 'score_expire',
    note: 'Hạn sử dụng của tiêu điểm, ví dụ: khách có 12 điểm có hạn đến "30/09/2025"'
  },
  {
    name: 'HSD điểm theo ngày',
    key: 'score_expire_countdown',
    note: 'Hiển thị còn bao nhiêu ngày thì tiêu điểm có khách hết hạn, ví dụ: hôm nay là 12/09/2024, khách có 10 điểm có hạn đến 20/09/2024 => còn 8 ngày'
  },
  {
    name: 'Cửa hàng',
    key: 'store_name',
    note: 'Tên cửa hàng mua của khách hàng'
  },
  {
    name: 'Địa chỉ cửa hàng',
    key: 'store_address',
    note: 'Địa chỉ cửa hàng mua của khách hàng'
  },
  {
    name: 'Địa chỉ google map',
    key: 'store_map_url',
    note: 'Link tra vị trí cửa hàng mua trên google map'
  }
]
